(function () {
  'use strict';

  class ScoutingGameSelectorController {
    private $scope;
    private $gameService;
    private $seasonService;

    private seasonId;
    private team;
    private game;

    private queryText;
    private promise;

    constructor($scope, GameService, SeasonService) {
      this.$scope = $scope;
      this.$gameService = GameService;
      this.$seasonService = SeasonService;
    }

    $onInit() {
      this.game = null;

      if (!this.team._id) {
        this.createNewGame();
      }

      this.$scope.$watchCollection(
        () => [this.team, this.seasonId],
        () => {
          this.promise = null;
        },
      );
    }

    findGames(query) {
      if (!this.promise) {
        this.promise = this.$gameService.getTeamSeasonSchedule(this.team._id, this.seasonId, {
          skip: 0,
          limit: 9999,
          sort: 'date',
          direction: -1,
          showAllGames: 1,
        });
      }

      return this.promise.then((items) => items.filter(this.createGameComparator(query)));
    }

    createNewGame() {
      if (!this.seasonId) {
        return;
      }

      let date;

      const [seasonStartYearStr] = this.seasonId.split('-');
      const seasonStartYear = Number(seasonStartYearStr);

      if (seasonStartYear == this.$seasonService.getCurrentSeasonLegacy()) {
        date = new Date();
      } else if (seasonStartYear < this.$seasonService.getCurrentSeasonLegacy()) {
        date = this.$seasonService.getSeasonDatesLegacy(seasonStartYear).end;
      } else if (seasonStartYear > this.$seasonService.getCurrentSeasonLegacy()) {
        date = this.$seasonService.getSeasonDatesLegacy(seasonStartYear).start;
      }

      this.game = { date };
    }

    private createGameComparator(query) {
      query = _.deburr(query).trim().toLocaleLowerCase();

      return function (item) {
        const homeTeamName = _.deburr(item.home.name).trim().toLocaleLowerCase();
        const awayTeamName = _.deburr(item.away.name).trim().toLocaleLowerCase();

        return homeTeamName.indexOf(query) >= 0 || awayTeamName.indexOf(query) >= 0;
      };
    }
  }

  angular.module('app.scouting').component('scoutingGameSelector', {
    templateUrl: 'scouting/components/general/game-selector.html',
    controller: ScoutingGameSelectorController,
    bindings: {
      seasonId: '<',
      team: '<',
      game: '=',
    },
  });
})();
