import { ReportState } from '../services/PendingReportsService';

class ReportSetupModalController {
  private $scoutingTemplateResource;
  private $searchService;
  private $seasonService;
  private $seasonFetcherService;
  private $pendingReportsService;
  private $scoutingService;
  private $scoutingReportResource;

  private $uibModal;
  private autoselect;

  private promise;
  private availableTemplates;
  private availableSeasons;
  private selected: ReportState;

  constructor(
    private $scope,
    private $state,
    private $q,
    ScoutingTemplateResource,
    SearchService,
    SeasonService,
    SeasonFetcherService,
    ScoutingReportResource,
    ScoutingService,
    private TEAM_IMPORTANT_PROPERTIES,
    private LEAGUE_IMPORTANT_PROPERTIES,
  ) {
    this.$scoutingTemplateResource = ScoutingTemplateResource;
    this.$searchService = SearchService;
    this.$seasonService = SeasonService;
    this.$seasonFetcherService = SeasonFetcherService;
    this.$scoutingService = ScoutingService;
    this.$scoutingReportResource = ScoutingReportResource;
  }

  $onInit() {
    this.availableTemplates = this.$scoutingTemplateResource.query();
    this.availableSeasons = [];
    this.promise = this.availableTemplates.$promise;

    if (this.autoselect.team) {
      this.onTeamSelected(this.autoselect.team);
    }

    this.selected = {
      template: null,
      primaryLeague: this.autoselect.league
        ? _.pick(this.autoselect.league, this.LEAGUE_IMPORTANT_PROPERTIES)
        : null,
      primaryTeam: this.autoselect.team
        ? _.pick(this.autoselect.team, this.TEAM_IMPORTANT_PROPERTIES)
        : null,
      primaryPlayerId: this.autoselect.playerIds ? this.autoselect.playerIds[0] : null,

      seasonId: this.$seasonService.getSeasonId(),
      players: [],
    };
    // We need to catch the populatingTeamRoster event emit from `scouting-roster` and broadcast it to `scouting-game-detail`
    // since these components are on different level and `scouting-game-detail` needs to know if `scouting-roster` is fetching API to disable/enable the team swapping button correctly
    this.$scope.$on('scouting:roster:populateTeamRoster', (__, { populatingTeamRoster }) => {
      this.$scope.$broadcast('scouting:reportSetup:populatingTeamRoster', {
        populatingTeamRoster,
      });
    });
  }

  onTeamSelected(item) {
    this.$seasonFetcherService.getSeasons('team', item._id).then((seasons) => {
      this.availableSeasons = seasons;
    });
  }

  /**
   * Needed because we store `report.season` as legacy season
   * And when opening a scouting report, that season is passed through to <scouting-roster>
   * And we need to keep it legacy season to make it work
   */
  toLegacySeason(seasonId) {
    const [seasonStartYearStr] = seasonId.split('-');
    const seasonStartYear = Number(seasonStartYearStr);
    return seasonStartYear;
  }

  proceed() {
    const report = new this.$scoutingReportResource({
      players: this.selected.players,
      season: this.toLegacySeason(this.selected.seasonId),
      template: this.selected.template,

      primaryTeam: this.selected.primaryTeam,
      primaryPlayerId: this.selected.primaryPlayerId,
    });

    if (report.template.taggingElement) {
      report.tags = this.selected.tags;
    }

    if (report.template.gameElement) {
      report.game = this.selected.game;
      report.teams = [report.game.home, report.game.away];
      report.league = report.game.league;
    } else {
      report.teams = [this.selected.primaryTeam];
      report.league =
        this.selected.primaryLeague || _.get(this.selected, 'primaryTeam.latestTeamStats.league');
    }

    this.promise = report.$save();
    this.promise.then(() => {
      this.$uibModal.close(report._id);
    });
  }

  cancel() {
    this.$uibModal.dismiss();
  }

  async findTeams(query) {
    const [searchResult, temporaryTeams] = await this.$q.all([
      this.$searchService.searchForTeams(query),
      this.$scoutingService.findTemporaryTeams(query),
    ]);

    const existingTeams = searchResult.teams.map((item) =>
      _.pick(item._source, this.TEAM_IMPORTANT_PROPERTIES),
    );
    const foundTeams = existingTeams.concat(temporaryTeams);

    if (temporaryTeams.some((team) => team.name == query)) {
      return foundTeams;
    } else {
      return foundTeams.concat([
        {
          name: query,
          temporaryId: this.$scoutingService.createTemporaryId(),
        },
      ]);
    }
  }
}

angular.module('app.scouting').component('modalReportSetup', {
  templateUrl: 'scouting/components/modals/report-setup.html',
  controller: ReportSetupModalController,
  bindings: {
    $uibModal: '<modalInstance',
    autoselect: '<',
  },
});
