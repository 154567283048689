var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ScoutingRosterController = (function () {
    function ScoutingRosterController($scope, $q, TeamService, PlayerService, SearchService, ScoutingService, PLAYER_IMPORTANT_PROPERTIES) {
        this.$scope = $scope;
        this.$q = $q;
        this.PLAYER_IMPORTANT_PROPERTIES = PLAYER_IMPORTANT_PROPERTIES;
        this.$teamService = TeamService;
        this.$playerService = PlayerService;
        this.$searchService = SearchService;
        this.$scoutingService = ScoutingService;
    }
    ScoutingRosterController.prototype.$onInit = function () {
        var _this = this;
        this.players = this.players || [];
        this.$scope.$watchGroup([function () { return _this.teams; }, function () { return _this.season; }], function (current, previous) {
            return _this.update(current != previous);
        });
    };
    ScoutingRosterController.prototype.update = function (force) {
        var _this = this;
        if (force) {
            _.remove(this.players);
        }
        else {
            _.remove(this.players, function (item) { return !_this.teams.some(function (team) { return item.team == (team._id || team.temporaryId); }); });
        }
        this.promise = this.$q.resolve().then(function () { return __awaiter(_this, void 0, void 0, function () {
            var missingRosterTeams, _i, missingRosterTeams_1, team;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        missingRosterTeams = this.teams.filter(function (team) { return !_this.players.some(function (item) { return item.team == (team._id || team.temporaryId); }); });
                        _i = 0, missingRosterTeams_1 = missingRosterTeams;
                        _a.label = 1;
                    case 1:
                        if (!(_i < missingRosterTeams_1.length)) return [3, 4];
                        team = missingRosterTeams_1[_i];
                        return [4, this.populateTeamRoster(team)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3, 1];
                    case 4:
                        this.brief = false;
                        this.players.forEach(function (wrapper) {
                            if (_this.autoselectedPlayerIds && _this.autoselectedPlayerIds.includes(wrapper.player._id)) {
                                wrapper.selected = true;
                                _this.brief = _this.compact;
                            }
                        });
                        return [2];
                }
            });
        }); });
    };
    ScoutingRosterController.prototype.isPositionMatches = function (position) {
        return function (wrapper) {
            switch (wrapper.player.playerPosition) {
                case 'CENTRE':
                case 'RIGHT_WING':
                case 'LEFT_WING':
                case 'FORWARD':
                    return position == 'FORWARD';
                case 'DEFENSEMAN':
                case 'LEFT_DEFENSEMAN':
                case 'RIGHT_DEFENSEMAN':
                    return position == 'DEFENSEMAN';
                case null:
                case undefined:
                case 'PLAYER':
                    return position == 'PLAYER';
                default:
                    return position == wrapper.player.playerPosition;
            }
        };
    };
    ScoutingRosterController.prototype.findPlayer = function (query, isGoalie) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.$searchService.searchForPlayers(query, {
                            goalie: isGoalie,
                            seasonId: this.toSeasonId(this.season),
                        })];
                    case 1:
                        result = _a.sent();
                        return [2, result.players.map(function (item) {
                                return {
                                    player: _.pick(item._source, _this.PLAYER_IMPORTANT_PROPERTIES),
                                    selected: false,
                                    custom: true,
                                };
                            })];
                }
            });
        });
    };
    ScoutingRosterController.prototype.createSelectionObserver = function (position, team) {
        var _this = this;
        return new SelectionObserver(function (wrapper) {
            team.$$addPlayerToRosterState[position] = false;
            if (wrapper) {
                if (!wrapper.player.playerPosition) {
                    wrapper.player = Object.assign(wrapper.player, { playerPosition: position });
                }
                _.remove(_this.players, function (inner) { return inner.player._id === wrapper.player._id; });
                _this.players.push(Object.assign({ team: team._id || team.temporaryId }, wrapper));
            }
        });
    };
    ScoutingRosterController.prototype.hasUnclassifiedPlayers = function (players) {
        return players && !!players.find(function (item) { return !item.player.playerPosition; });
    };
    ScoutingRosterController.prototype.toSeasonId = function (legacySeason) {
        return "".concat(legacySeason, "-").concat(legacySeason + 1);
    };
    ScoutingRosterController.prototype.populateTeamRoster = function (team) {
        return __awaiter(this, void 0, void 0, function () {
            var roster, roster, _loop_1, this_1, _i, _a, playerId;
            var _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.$scope.$emit('scouting:roster:populateTeamRoster', { populatingTeamRoster: true });
                        if (!(team && team._id)) return [3, 2];
                        return [4, this.$teamService.getGroupedRoster(team._id, this.toSeasonId(this.season))];
                    case 1:
                        roster = _c.sent();
                        __spreadArray(__spreadArray(__spreadArray(__spreadArray([], (roster.GOALIE || []), true), (roster.DEFENSEMAN || []), true), (roster.FORWARD || []), true), (roster.PLAYER || []), true).forEach(function (wrapper) {
                            _this.players.push({
                                team: team._id,
                                jerseyNumber: wrapper.jerseyNumber,
                                player: _.pick(wrapper.player, _this.PLAYER_IMPORTANT_PROPERTIES),
                                selected: false,
                            });
                        });
                        return [3, 4];
                    case 2:
                        if (!(team && team.temporaryId)) return [3, 4];
                        return [4, this.$scoutingService.getTemporaryRoster(team.temporaryId)];
                    case 3:
                        roster = _c.sent();
                        (_b = this.players).splice.apply(_b, __spreadArray([0, 0], roster, false));
                        _c.label = 4;
                    case 4:
                        if (!(team && this.autoselectedPlayerIds && this.forceAddPlayersTo === team)) return [3, 8];
                        _loop_1 = function (playerId) {
                            var player;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        if (!!this_1.players.some(function (wrapper) { return wrapper.player._id === playerId; })) return [3, 2];
                                        return [4, this_1.$playerService.getPlayer(playerId)];
                                    case 1:
                                        player = _d.sent();
                                        this_1.players.push({
                                            team: team._id,
                                            jerseyNumber: null,
                                            player: _.pick(player, this_1.PLAYER_IMPORTANT_PROPERTIES),
                                            selected: false,
                                        });
                                        _d.label = 2;
                                    case 2: return [2];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, _a = this.autoselectedPlayerIds;
                        _c.label = 5;
                    case 5:
                        if (!(_i < _a.length)) return [3, 8];
                        playerId = _a[_i];
                        return [5, _loop_1(playerId)];
                    case 6:
                        _c.sent();
                        _c.label = 7;
                    case 7:
                        _i++;
                        return [3, 5];
                    case 8:
                        this.$scope.$emit('scouting:roster:populateTeamRoster', { populatingTeamRoster: false });
                        return [2];
                }
            });
        });
    };
    ScoutingRosterController.prototype.remove = function (wrapper) {
        if (this.players.includes(wrapper)) {
            this.players.splice(this.players.indexOf(wrapper), 1);
        }
    };
    return ScoutingRosterController;
}());
var SelectionObserver = (function () {
    function SelectionObserver(callback) {
        this.callback = callback;
        this.isInitialized = false;
    }
    Object.defineProperty(SelectionObserver.prototype, "player", {
        set: function (value) {
            if (!value && !this.isInitialized) {
                this.isInitialized = true;
                return;
            }
            this.callback(value);
        },
        enumerable: false,
        configurable: true
    });
    return SelectionObserver;
}());
angular.module('app.scouting').component('scoutingRoster', {
    templateUrl: 'scouting/components/general/roster.html',
    controller: ScoutingRosterController,
    bindings: {
        teams: '<',
        season: '<',
        readonly: '<',
        autoselectedPlayerIds: '<',
        forceAddPlayersTo: '<',
        players: '=',
        compact: '@',
    },
});
;